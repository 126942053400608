import React, { Component } from 'react'
import Header from "../components/Header"
import Footer from "../components/Footer"
import Items from "../components/Items"

export default class FollowSpot extends Component {
    render() {
        return (
            <div className = 'wrapper'>
                <div className = 'container'>
                    <Header />
                    <h2 className='catalogue-title'>Follow Spotlights</h2>
                    <h3 className='catalogue-text'>Anzhee LED Follow Spots offer a full range of spotlights to accentuate the artist persona at any distance. The fixtures’ design provides the key options required by this kind of equipment: bright light source, uniform lightspot, ergonomics and reliability. Product range includes simple cheap models and PRO-class equipment with motorised ZOOM, iris and focus.</h3>
                    <Items items = {this.props.items} />
                    <Footer />
                </div>
            </div>
          )
    }
}
