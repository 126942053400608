import React, { Component } from 'react'
import Header from "../components/Header"
import Footer from "../components/Footer"
import Items from "../components/Items"

export default class Wpip extends Component {
    render() {
        return (
            <div className = 'wrapper'>
                <div className = 'container'>
                    <Header />
                    <h2 className='catalogue-title'>WP/IP Series</h2>
                    <h3 className='catalogue-text'>Waterproof spotlights provide excellent outdoor stage lighting even in harsh weather conditions. It’s a great opportunity to hold an open air concert and arrange an event in a public park or in a town square with safety. The product range includes everything you need including special connectors, cables and accessories.</h3>
                    <Items items = {this.props.items} />
                    <Footer />
                </div>
            </div>
          )
    }
}
