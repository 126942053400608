import React, { Component } from 'react'
import Header from "../components/Header"
import Footer from "../components/Footer"
import Items from "../components/Items"

export default class Amplifiers extends Component {
    render() {
        return (
            <div className = 'wrapper'>
                <div className = 'container'>
                    <Header />
                    <h2 className='catalogue-title'>Amplifiers</h2>
                    <h3 className='catalogue-text'>Anzhee power amplifiers are budget-friendly, reliable and easy to operate. <br /><br /> KX are conventional analogue Class H amplifiers with good reliability, high protection and thermal stability. Amplifiers have all necessary inputs, convenient LED indication, modern short-circuit protection systems, soft-start function and sensitivity controls. <br /><br /> Digital PFC series is a flexible platform that utilizes the latest patented D-class amplification circuitry with excellent power and energy efficiency while minimizing distortion of the effective signal.</h3>
                    <Items items = {this.props.items} />
                    <Footer />
                </div>
            </div>
          )
    }
}
