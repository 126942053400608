import React, { Component } from 'react'
import Header from "../components/Header"
import Footer from "../components/Footer"
import Items from "../components/Items"

export default class BarSpotlights extends Component {
    render() {
        return (
            <div className = 'wrapper'>
                <div className = 'container'>
                    <Header />
                    <h2 className='catalogue-title'>LED BAR Lights</h2>
                    <h3 className='catalogue-text'>Linear LED lights can serve as washers, scenery and background backlighting as well as conventional blinders. These fixtures can be easily mounted on a soffit or placed on the floor. LED BARs with pixel control successfully working on the audience or perform colorful effects in combination with stage fog.</h3>
                    <Items items = {this.props.items} />
                    <Footer />
                </div>
            </div>
          )
    }
}
