import React, { Component } from 'react'
import Header from "../components/Header"
import Footer from "../components/Footer"
import Items from "../components/Items"

export default class AdmarkAmplifiers extends Component {
    render() {
        return (
            <div className = 'wrapper'>
                <div className = 'container'>
                    <Header />
                    <h2 className='catalogue-title'>Admark Amplifiers</h2>
                    <h3 className='catalogue-text'>Admark is one of the best Pro Audio suppliers in China, specialized in the manufacture and design of professional loudspeakers and amplifiers since 1998. A new generation of high-performance digital amplifiers are developed especially for Admark line array systems or similar powerful stadium acoustics. A variety of D-class devices allow flexibility in the on-site amplification.</h3>
                    <Items items = {this.props.items} />
                    <Footer />
                </div>
            </div>
          )
    }
    
}
