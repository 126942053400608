import React, { Component } from 'react'
import Header from "../components/Header"
import Footer from "../components/Footer"
import Items from "../components/Items"

export default class TheaterLighting extends Component {
    render() {
        return (
            <div className = 'wrapper'>
                <div className = 'container'>
                    <Header />
                    <h2 className='catalogue-title'>Theater Lighting and Studio Equipment</h2>
                    <h3 className='catalogue-text'>Anzhee theatrical fixtures are designed for those who prefer only the best solutions with high colour rendering index and no light artefacts on camera. From Fresnel lens luminaires and flood frosted LED panels to profiles with different lens modules, we provide lighting designers with complete freedom to choose their theatre and studio lighting tools. And exclusive Q-Lumine and Spectrum series enhance tech customization to its maximum.</h3>
                    <Items items = {this.props.items} />
                    <Footer />
                </div>
            </div>
          )
    }
}
