import React, { Component } from 'react'
import Header from "../components/Header"
import Footer from "../components/Footer"
import Items from "../components/Items"

export default class SplittersAntennas extends Component {
    render() {
        return (
            <div className = 'wrapper'>
                <div className = 'container'>
                    <Header />
                    <h2 className='catalogue-title'>Splitters and Antennas</h2>
                    <h3 className='catalogue-text'>A set of splitters and antennas helps to build a complete multi-channel radio system for events with a large number of speakers. The Anzhee series features active and passive antennas and high quality, easy-to-set-up splitter-distributors that enhance radio transmission quality, reduce signal-to-noise ratio and optimize the RF path as exact stage requires.</h3>
                    <Items items = {this.props.items} />
                    <Footer />
                </div>
            </div>
          )
    }
}
