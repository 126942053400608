import React, { Component } from 'react'
import Header from "../components/Header"
import Footer from "../components/Footer"
import Items from "../components/Items"

export default class AudioProcessors extends Component {
    render() {
        return (
            <div className = 'wrapper'>
                <div className = 'container'>
                    <Header />
                    <h2 className='catalogue-title'>Audio Processors</h2>
                    <h3 className='catalogue-text'>Anzhee processors feature a full range of options to meet all the requirements for this type of equipment. They provide accurate signal conversion with minimal noise errors. The frequency curve is controlled by Linkwitz-Riley, Bessel and Butterworth filters. <br /> <br /> The processor design includes all major safety systems to prevent possible malfunctions caused by incorrect operation.</h3>
                    <Items items = {this.props.items} />
                    <Footer />
                </div>
            </div>
          )
    }
}
