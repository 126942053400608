import React from 'react'

export default function Presentation() {
  return (
    <div className = 'presentation'>
        <h2>PROFESSIONAL STAGE </h2>
        <h2>LIGHTING & SOUND EQUIPMENT</h2>
        <p>FOR THEATRES, CONCERT HALLS, CULTURAL VENUES</p>
    </div>
  )
}
