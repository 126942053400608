import React, { Component } from 'react'
import Header from "../components/Header"
import Footer from "../components/Footer"
import Items from "../components/Items"

export default class RadioSystems extends Component {
    render() {
        return (
            <div className = 'wrapper'>
                <div className = 'container'>
                    <Header />
                    <h2 className='catalogue-title'>Radio Systems</h2>
                    <h3 className='catalogue-text'>Wireless microphone systems offer musicians, actors and speakers a true freedom in live performing both in stationary applications and at roadshows - from entreprise plays to conference events or festive occasions.  <br /> <br /> Anzhee radio systems provide high-quality professional sound right out of the box. The product range includes antenna or true diversity options with a variety of handheld, lavalier and headset configs.</h3>
                    <Items items = {this.props.items} />
                    <Footer />
                </div>
            </div>
          )
    }
}
