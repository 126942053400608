import React, { Component } from 'react'
import Header from "../components/Header"
import Footer from "../components/Footer"
import Items from "../components/Items"

export default class SpecialEffect extends Component {
    render() {
        return (
            <div className = 'wrapper'>
                <div className = 'container'>
                    <Header />
                    <h2 className='catalogue-title'>Special Effect</h2>
                    <h3 className='catalogue-text'>Various lighting effects are essential when creating a vibrant, spectacular performance. Supporting strobes and blinders are suitable for live music shows and rock concerts, while portable stand sets will be appreciated by DJs and owners of small venues. As for those who want to add a touch of flair to the scene, check out available vintage designs.</h3>
                    <Items items = {this.props.items} />
                    <Footer />
                </div>
            </div>
          )
    }
}
