import React, { Component } from 'react'
import Header from "../components/Header"
import Footer from "../components/Footer"
import Items from "../components/Items"

export default class Controlling extends Component {
    render() {
        return (
            <div className = 'wrapper'>
                <div className = 'container'>
                    <Header />
                    <h2 className='catalogue-title'>DMX Consoles & Controllers</h2>
                    <h3 className='catalogue-text'>Lighting control is a huge part of any stage setup. Anzhee Eventure lighting consoles are an exclusive series with a unique unified Linux-based operating system, providing the user with all relevant software features in an intuitive, ergonomic design. Eventure Series offer both portable backpack solutions and full option consoles for controlling large stationary installations in theatres, club venues and event areas.</h3>
                    <Items items = {this.props.items} />
                    <Footer />
                </div>
            </div>
          )
    }
}
