import React, { Component } from 'react'
import Header from "../components/Header"
import Footer from "../components/Footer"
import Items from "../components/Items"

export default class LinearArrays extends Component {
    render() {
        return (
            <div className = 'wrapper'>
                <div className = 'container'>
                    <Header />
                    <h2 className='catalogue-title'>Line Arrays</h2>
                    <h3 className='catalogue-text'>Anzhee line arrays bring powerful acoustic solutions for clubs, concert halls, open-air festivals. Compact Anzhee NANO array, CA fixed-angle system, TOUR active touring set-ups, iX high-power array – we know how to create tools for perfect live performance. The excellence of creative sound just a short walk away.</h3>
                    <Items items = {this.props.items} />
                    <Footer />
                </div>
            </div>
          )
    }
}
