import React, { Component } from 'react'
import Header from "../components/Header"
import Footer from "../components/Footer"
import Items from "../components/Items"

export default class Loudspeakers extends Component {
    render() {
        return (
            <div className = 'wrapper'>
                <div className = 'container'>
                    <Header />
                    <h2 className='catalogue-title'>Loudspeakers</h2>
                    <h3 className='catalogue-text'>The range of Anzhee and EASYSOUND brand loudspeakers includes the whole spectrum of stage acoustics: pole mount sub+satellite active systems, stage monitors, zone subsonic solutions, acoustic columns etc. You can both complement a large installation as well as arrange the sound design for small clubs, restaurants, places of worship.</h3>
                    <Items items = {this.props.items} />
                    <Footer />
                </div>
            </div>
          )
    }
}
