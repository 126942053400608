import React, { Component } from 'react'
import Header from "../components/Header"
import Footer from "../components/Footer"
import Items from "../components/Items"

export default class MovingHead extends Component {
    render() {
        // console.log(this.props.category)
        // console.log(this.props.items.filter(item => item.category === this.props.category.toString()))
        return (
            <div className = 'wrapper'>
                <div className = 'container'>
                    <Header />
                    <h2 className='catalogue-title'>Moving Head</h2>
                    <h3 className='catalogue-text'>Anzhee LED moving heads bring a true freedom and creative experience onto any kind of venues. Extremely versatile, multi-functional WASH, SPOT & hybrid dynamic lights are able to enhance your stage performance to its maximum. High-quality optics, bright light source, original gobos, prisms and other options allow you to design effects perfectly according your artistic vision. </h3>
                    <Items items = {this.props.items}/>
                    <Footer />
                </div>
            </div>
          )
    }
    
}
