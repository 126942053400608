import React, { Component } from 'react'
import Header from "../components/Header"
import Footer from "../components/Footer"
import Items from "../components/Items"

export default class MovingTiltBar extends Component {
    render() {
        return (
            <div className = 'wrapper'>
                <div className = 'container'>
                    <Header />
                    <h2 className='catalogue-title'>Moving Tilt BAR</h2>
                    <h3 className='catalogue-text'>Moving Tilt BARs provide massive beam effects so popular in the design of modern concerts and TV shows. In addition, they can be used to create a light curtain or for stage ramp purposes. With individual control and separate zoom for each LED these fixtures are some of the most impressive tools of the lighting designer.</h3>
                    <Items items = {this.props.items} />
                    <Footer />
                </div>
            </div>
          )
    }
}
